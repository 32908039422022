<template>
  <div class='footers'>
    <div class="cor">
      <span>xxx公司 版权所有©2020 </span>
      <span class="icp">备案号：粤ICP备19054747号-1</span>
    </div>
  </div>
</template>

<script>

export default {
  data () {
    return {

    }
  },
  created () {
  },
  methods: {
  },
}
</script>

<style scoped lang='scss'>
.footers {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100px;
  background-color: #244163;

  .cor {
    font-size: 14px;
    color: #fff;

    .icp {
      cursor: pointer;
    }

    .icp:hover {
      opacity: 0.5;
    }
  }
}
</style>
